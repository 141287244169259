import React, { Component, useState } from "react";
import Slider from "react-slick";
import Title from "./Title"
import BestFlatItem from "./BestFlatItem"


export default class BestFlatList extends Component {

    
    render() {
        const title = {
            text: "Services",
            description: "Our Core Services"
        }
        const data = [{
            name:"Road Construction",
            desc: "RSM Infratech specializes in constructing robust and long-lasting roads, from highways to urban streets. Our projects are designed to improve connectivity and reduce...",

            // desc: "RSM Infratech specializes in constructing robust and long-lasting roads, from highways to urban streets. Our projects are designed to improve connectivity and reduce travel time, enhancing the overall transportation infrastructure.",
            image:"/img/road.png"
        },
        {
            name:"Bridge Construction",
            desc: "Our expertise in bridge construction spans small-scale pedestrian bridges to large, complex structures. We focus on safety, durability, and aesthetics, ensuring that each bridge...",
            // desc: "Our expertise in bridge construction spans small-scale pedestrian bridges to large, complex structures. We focus on safety, durability, and aesthetics, ensuring that each bridge is a lasting asset to the community.",
            image:"/img/bridge.png"
        },
        {
            name:"Irrigation Dams",
            // desc: "We construct irrigation dams that play a crucial role in water management, supporting agriculture, and protecting communities from floods. Our projects are built to withstand the test of time and environmental challenges.",
            desc: "We construct irrigation dams that play a crucial role in water management, supporting agriculture, and protecting communities from floods. Our projects are built to...",
            image:"/img/dam.png"
        },
        {
            name:"Government Infrastructure Projects",
            // desc: "RSM Infratech partners with government agencies to deliver essential infrastructure projects, including schools, hospitals, and public buildings. Our projects are aligned with national development goals and contribute to the country’s progress.",
            desc: "RSM Infratech partners with government agencies to deliver essential infrastructure projects, including schools, hospitals, and public buildings. Our projects...",
            image:"/img/project.png"
        }
    
    ]
        const settings = {
            infinite: true,
            speed: 1500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoPlay: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                }
            ]
        };
        return (
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={title.text} description={title.description} />
                            <Slider {...settings}>
                                {console.log(data)}
                                {data.map((data, index) => {
                                    return(
                                        <BestFlatItem data={data} key={index}/>
                                    )
                                })}
                                {/* <BestFlatItem flatState="For Sale" data={data[1]} />
                                <BestFlatItem flatState="For Rent" data={data[2]}/>
                                <BestFlatItem flatState="For Rent" data={data[3]}/>
                                <BestFlatItem flatState="For Sale"  />
                                <BestFlatItem flatState="For Rent"  /> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}