import { useEffect } from "react";

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      });
    return (
        <section className="about">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">About</h1>
                            {/* <h2 className="page-description">About</h2> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/img/govt.jpg" alt="product" className="w-100" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-item">
                                <div className="title">
                                    About RSM Infratech
                            </div>
                                <div className="about-text">
                                RSM Infratech is a leading construction company with a reputation for delivering high-quality infrastructure projects. Specializing in roads, bridges, irrigation dams, and government infrastructure, we are committed to building a stronger, more connected nation. Our experienced team, state-of-the-art technology, and dedication to excellence ensure that every project we undertake meets the highest standards of quality and sustainability.
                                    
                                    </div>
                                    
                            </div>
                        </div>
                    </div>
                    <h2>Why Choose Us ??</h2>
                                <div className="about-features">
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i>Expertise: Over a decade of experience in construction and infrastructure.</p>
                                    <p className="about-feature" ><i className="fas fa-long-arrow-alt-right"></i>Quality: Commitment to delivering durable and efficient infrastructure.</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i>Innovation: Leveraging the latest technology for superior results.</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i>Sustainability: Focus on eco-friendly and sustainable construction practices.</p>
                                    
                                </div>
                </div>
            </div>
        </section>
    )
}

export default About