import BlogItem from "./BlogItem"
import { useEffect } from 'react'




function Blog () {

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const services = [
    {
        name: "Road Construction",
        desc: "RSM Infratech specializes in constructing robust and long-lasting roads, ranging from major highways to intricate urban streets. Our projects are meticulously designed to enhance connectivity, reduce travel time, and ensure smooth and efficient transportation. We employ advanced engineering techniques and high-quality materials to create roads that withstand heavy traffic and harsh weather conditions, contributing significantly to the nation's infrastructure development.",
        image: "/img/road.png"
    },
    {
        name: "Bridge Construction",
        desc: "Our expertise in bridge construction covers a wide spectrum, from small-scale pedestrian bridges to large, complex structures spanning rivers and valleys. We prioritize safety, durability, and aesthetics in every project, ensuring that each bridge is not only functional but also a visually appealing landmark. By integrating innovative design with cutting-edge construction technologies, RSM Infratech delivers bridges that are engineered to endure the test of time and serve as vital connections for communities.",
        image: "/img/bridge.png"
    },
    {
        name: "Irrigation Dams",
        desc: "At RSM Infratech, we construct irrigation dams that are pivotal in sustainable water management. These dams support agricultural activities by providing a reliable water supply, mitigate flood risks, and help in conserving water resources. Our projects are meticulously planned and executed to ensure structural integrity and resilience against environmental challenges. We are committed to creating infrastructures that boost agricultural productivity and safeguard communities from natural disasters.",
        image: "/img/dam.png"
    },
    {
        name: "Government Infrastructure Projects",
        desc: "RSM Infratech partners with government agencies to deliver critical infrastructure projects that align with national development goals. From schools and hospitals to public buildings and community centers, we are dedicated to contributing to the nation’s progress by constructing facilities that serve the public good. Our approach ensures that each project is delivered on time, within budget, and meets the highest standards of quality. We are proud to play a role in building the backbone of the country’s infrastructure.",
        image: "/img/project.png"
    }
];

    
    return (
        <div>
           
            <section class="blog">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Services</h1>
                            {/* <h2 className="page-description">About</h2> */}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{margin: '20px'}}>
            {services.map((data, index) => {
                    return(
                        <>
                        {index % 2 == 0 ? 
                        <div class="content-item">
                        <div class="content-image">
                            <img src={data?.image}alt={data?.name} />
                        </div>
                        <div class="content-text">
                            
                            <h1>{data?.name}</h1>
                            <p>{data?.desc}</p>
                        </div>
                    </div>
                        :
                        <div class="content-item reverse">
        <div class="content-text">
            <h1>{data?.name}</h1>
            <p>{data?.desc}</p>
        </div>
        <div class="content-image">
            <img src={data?.image} alt={data?.name} />
        </div>
    </div>
                        }
                        
                        
                         

                        </>
                        
                       
                    )
                })}
            </div>
                
    

    
</section>
        </div>
    )
}

export default Blog;