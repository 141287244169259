import {Link} from "react-router-dom"


const BestFlatItem = (props) => {
    
    return (
        
        <div className="best-estate" >
            <Link to="/services">
            <div className="best-estate-item">
                <div className="best-estate-img-area">
                    <img className="best-estate-img" src={props?.data?.image} alt="flat" />
                    {/* <div className={`best-estate-state ${props?.flatState ==="For Rent" ? "bg-green" : "bg-red" }`}>{props?.flatState}</div> */}
                </div>
                <div className="best-estate-content" >
                    <h4>{props?.data?.name}</h4>
                    <span>{props?.data?.desc}</span>
                </div>
                {/* <div className="best-estate-features">
                    <div className="d-flex">
                        <div className="best-estate-feature">
                            <i className="fas fa-check-circle"></i>
                            <span>3 Beds</span>
                        </div>
                        <div className="best-estate-feature">
                            <i className="fas fa-check-circle"></i>
                            <span>2 Bathrooms</span>
                        </div>
                    </div>
                    <h5 className="best-estate-price">$650</h5>
                </div> */}
            </div>
            </Link>
        </div>
    )
}

export default BestFlatItem