import { useEffect, useState } from "react"
import banner from "../company.jpg"
import { Link } from "react-router-dom";
import Title from "./Title";
const Banner = () => {
    const [search, setSearch] = useState();
    const [find, setFind] = useState([]);
    const [word, setWord] = useState("");
    useEffect(() => {
        setSearch(["a","b","test", "mb"])
    }, [])
    const findSearch = (e) => {
        setWord(e.target.value)
        const filteredCountries = search.filter(item => item.indexOf(e.target.value) > -1 ? item : null);
        e.target.value.length === 0 ? setFind([]) : setFind(filteredCountries);
    }
    const findResult = () => {
        if (find.length === 0 && word.length > 0) {
            return <div className="find-search">Not Found</div>
        }
        if (find.length > 0) {
            return <div className="find-search">
                {
                    find.map(item => {
                        return <Link key={item} to="#">{item}</Link>
                    })
                }
            </div>
        }
    }
    return (
        <>
        <div className="banner d-flex align-items-center" style={{ backgroundImage: `url(${banner})` }}>
            <div className="bg-custom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="banner-area text-center pt-4 pb-4">
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                <h2 className="mt-2 mb-4 banner-title"><strong> Welcome to RSM Infratech</strong> </h2>
                                <div>
                                    <p>
                                    RSM Infratech is a leading construction company with a reputation for delivering high-quality infrastructure projects. Specializing in roads, bridges, irrigation dams, and government infrastructure, we are committed to building a stronger, more connected nation. Our experienced team, state-of-the-art technology, and dedication to excellence ensure that every project we undertake meets the highest standards of quality and sustainability.
                                    </p>
                                    </div>
                                {findResult()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container pt-5 pb-5" style={{textAlign: 'center'}}>
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title="Our Vision & Values"  />
                           
                        </div>
                        <div>
                        At RSM, our vision is to be a leader in the construction industry by delivering innovative, durable, and sustainable infrastructure projects that stand the test of time. We believe that every road, bridge, dam, and public building we construct is more than just a project—it’s a commitment to enhancing the lives of the communities we serve and contributing to the progress of our nation.

We are driven by a passion for excellence and a dedication to quality. Our approach is rooted in the belief that infrastructure is the backbone of development, and we strive to build structures that not only meet today’s needs but also anticipate the challenges of tomorrow. By leveraging cutting-edge technology, adhering to the highest standards of safety, and fostering a culture of continuous improvement, we aim to set new benchmarks in the construction industry.

RSM is committed to sustainability and environmental stewardship. We integrate eco-friendly practices into our projects, ensuring that our work contributes to a greener, more resilient future. Our vision is to create infrastructure that supports economic growth, enhances connectivity, and improves the quality of life for all.

As we look to the future, we are dedicated to expanding our capabilities, exploring new opportunities, and building strong partnerships that enable us to deliver on our promise of excellence. Together with our clients, partners, and communities, we are building a better future—one project at a time.
                        </div>
                    </div>
                </div>
        </>
        
    )
}

export default Banner;